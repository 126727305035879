import React from "react";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "components/Layout/Layout";
import { ButtonMedium } from "components/Design/Button/ButtonMedium";
import PageContext from "contexts/PageContext";
import { CRUISE_INQUIRY } from "store/constants/support";
import { generateSupportURL } from "utils/generateSupportURL";
import { transformObject } from "utils/transformv4";

const LakodiaThankYou = ({ data }) => {
  const { t } = useTranslation("homepage");
  const {
    cms,
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const {
    sections: [destinations],
    hotelsByLocale: [hotel],
    supportDrawer: [supportDrawer],
  } = transformObject(cms);

  const dataContact = {
    url: generateSupportURL("labuan-bajo/lakodia", CRUISE_INQUIRY),
    label: t("Contact Us"),
  };

  const supportDrawerData = {
    ...supportDrawer,
    hotel: hotel,
  };

  return (
    <PageContext.Provider value={{}}>
      <Layout
        destination={t("Komodo")}
        seoTitle={t("Ayana Komodo")}
        showBanner={true}
        destinationCards={destinations.images}
        navigations={navigations}
        hotel={hotel}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with AYANA Lako di'a")}
        contactOnMobile={dataContact}
        contactOnDesktop={dataContact}
        supportDrawer={supportDrawerData}>
        <div className="py-40 md:py-60 text-center">
          <h1 className="text-m-h2 md:text-h1 font-semibold text-gray-text pb-12 md:pb-16">
            {t("Thank You")}
          </h1>
          <p className="w-9/12 md:w-2/6 m-auto text-gray-text text-12-20 md:text-16-24 pb-16 md:pb-24">
            {t("processingRequest")}
          </p>
          <Link to={generateSupportURL("labuan-bajo/lakodia")}>
            <ButtonMedium type="primary" label={t("backToSupport")} />
          </Link>
        </div>
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query LakodiaThankYou($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNavigationMenuJson(filter: { hotel: { eq: "AYANA Lako Di'a" } }) {
      ...LocalNavigation
    }
    allSocialMediaJson(filter: { hotel: { eq: "AYANA Lako Di'a" } }) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }
    cms {
      supportDrawer: sections(filters: { name: { eq: "LKD_Support_Drawer" } }) {
        ...NavSections
      }
      hotelsByLocale(language: $language, where: { id: 5 }) {
        data {
          id
          attributes {
            ...NavHotel
          }
        }
      }
      sections(filters: { name: { eq: "LKD_Footer_Destination" } }) {
        ...NavSections
      }
    }
  }
`;
export default LakodiaThankYou;
